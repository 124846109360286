/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: 'Satoshi';
  src: url('../../../../fonts/Satoshi-Regular.woff2') format('woff2'), // Asegúrate de cambiar la ruta
  url('../../../../fonts/Satoshi-Regular.woff') format('woff');
  font-weight: 400; // Normal
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../../../../fonts/Satoshi-Bold.woff2') format('woff2'),
  url('../../../../fonts/Satoshi-Bold.woff') format('woff');
  font-weight: 700; // Bold
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../../../../fonts/Satoshi-Medium.woff2') format('woff2'),
  url('../../../../fonts/Satoshi-Medium.woff') format('woff');
  font-weight: 500; // Black
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
@font-face {
  font-family: "Fira Code", monospace;
  font-weight: 500; // Black
  font-style: normal;
}

.font-monospace {
  font-family: "Fira Code", monospace;
}

.fa {
  min-width: 1rem;
  min-height: 1rem;
}

.pi {
  font-family: 'Font Awesome 6 Free';
  speak: none;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-spin {
  animation: fa-spin 2s infinite linear;
}

.pi-angle-left:before {
  content: "\f104";
}

.pi-angle-double-left:before {
  content: "\f100";
}

.pi-angle-right:before {
  content: "\f105";
}

.pi-angle-double-right:before {
  content: "\f101";
}

.pi-calendar:before {
  content: "\f073"
}

.pi-check:before {
  content: "\f00c";
}

.pi-chevron-left:before {
  content: "\f053";
}

.pi-chevron-right:before {
  content: "\f054";
}

.pi-chevron-down:before {
  content: "\f078";
}

.pi-check:before {
  content: "\f00c";
}

.pi-chevron-down:before {
  content: "\f078";
}

.pi-filter:before {
  content: "\f0b0";
}

.pi-filter-slash:before {
  content: "\e17b";
}

.pi-spinner:before {
  content: "\f1ce";
}

.pi-search:before {
  content: "\f002";
}

.pi-search:before {
  content: "\f002";
}

.pi-sort-alt:before {
  content: "\f0dc";
}

.pi-sort-amount-up-alt:before {
  content: "\f0de";
}

.pi-sort-amount-down:before {
  content: "\f0dd";
}

.pi-times:before {
  content: "\f00d";
}

html {
  font-size: 14px;
}

body {
  font-size: 14px;
  margin: 0;
}
