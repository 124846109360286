/* You can add global styles to this file, and also import other style files */
//@import "assets/styles/variables";
//@import "assets/styles/theme";

@import "assets/styles/themes/lara/lara-light/blue/theme";

///*!
// * Bootstrap v5.1.3 (https://getbootstrap.com/)
// * Copyright 2011-2021 The Bootstrap Authors
// * Copyright 2011-2021 Twitter, Inc.
// * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
// */

////
////// Helpers
////@import "~bootstrap/scss/helpers";
////
////// Utilities
////@import "~bootstrap/scss/utilities/api";
////// scss-docs-end import-stack
////@import "bootstrap/scss/bootstrap";
////@import "~assets/styles/variables";
//
//// Spinners
////@import "./assets/styles/buttons";
////@import "./assets/styles/spinners";
////@import "./assets/styles/datatables";
////@import "./assets/styles/tables";
////@import "./assets/styles/cards";
////@import "./assets/styles/paginator";
//@import "./assets/styles/typography";
//@import "./assets/styles/inputs";


///**
//* Template Name: NiceAdmin - v2.2.2
//* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
//* Author: BootstrapMade.com
//* License: https://bootstrapmade.com/license/
//*/
////@import "~@ng-select/ng-select/themes/default.theme.css";
//
///*--------------------------------------------------------------
//# General
//--------------------------------------------------------------*/
//$web-font-path: "https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap" !default;
//@import url($web-font-path);
//@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');
//:root {
//  scroll-behavior: smooth;
//}
//
//html {
//  font-size: 14px;
//}
//
//body {
//  font-family: "Lato", sans-serif;
//  //font-size: 14px;
//  background: #f6f9ff;
//  color: #444444;
//}
//
///* Cambia la fuente de todos los componentes de PrimeNG a "Lato" */
////.p-component,
////.p-table,
////.p-card,
////.p-link {
////  font-family: 'Lato', sans-serif;
////  font-size: 0.875rem;
////}
//
//a {
//  color: #4154f1;
//  text-decoration: none;
//}
//
//a:hover {
//  color: #717ff5;
//  text-decoration: none;
//}
//
//h1, h2, h3, h4, h5, h6 {
//  font-family: "Lato", sans-serif;
//}
//
///*--------------------------------------------------------------
//# Main
//--------------------------------------------------------------*/
//#main {
//  margin-top: 54px;
//  padding: 24px 24px;
//  transition: all 0.3s;
//}
//
//@media (max-width: 1199px) {
//  #main {
//    padding: 20px;
//  }
//}
//
//@media (max-width: 576px) {
//  #main {
//    padding: 16px;
//  }
//}
//
//@media screen and (max-width: 576px) {
//  .card-footer .btn {
//    width: 100%;
//    display: block;
//    margin-bottom: 0.25rem;
//  }
//}
//
///*--------------------------------------------------------------
//# Page Title
//--------------------------------------------------------------*/
//.pagetitle {
//  margin-bottom: 10px;
//}
//
//.pagetitle h1 {
//  font-size: 24px;
//  margin-bottom: 0;
//  font-weight: 700;
//  color: #012970;
//}
//
///*--------------------------------------------------------------
//# Back to top button
//--------------------------------------------------------------*/
//.back-to-top {
//  position: fixed;
//  visibility: hidden;
//  opacity: 0;
//  right: 15px;
//  bottom: 15px;
//  z-index: 99999;
//  background: #4154f1;
//  width: 40px;
//  height: 40px;
//  border-radius: 4px;
//  transition: all 0.4s;
//}
//
//.back-to-top i {
//  font-size: 24px;
//  color: #fff;
//  line-height: 0;
//}
//
//.back-to-top:hover {
//  background: #6776f4;
//  color: #fff;
//}
//
//.back-to-top.active {
//  visibility: visible;
//  opacity: 1;
//}
//
///*--------------------------------------------------------------
//# Override some default Bootstrap stylings
//--------------------------------------------------------------*/
///* Dropdown menus */
//.dropdown-menu {
//  border-radius: 4px;
//  padding: 10px 0;
//  -webkit-animation-name: dropdown-animate;
//  animation-name: dropdown-animate;
//  -webkit-animation-duration: 0.2s;
//  animation-duration: 0.2s;
//  -webkit-animation-fill-mode: both;
//  animation-fill-mode: both;
//  border: 0;
//  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
//}
//
//.dropdown-menu .dropdown-header, .dropdown-menu .dropdown-footer {
//  text-align: center;
//  font-size: 15px;
//  padding: 10px 25px;
//}
//
//.dropdown-menu .dropdown-footer a {
//  color: #444444;
//  text-decoration: underline;
//}
//
//.dropdown-menu .dropdown-footer a:hover {
//  text-decoration: none;
//}
//
//.dropdown-menu .dropdown-divider {
//  color: #a5c5fe;
//  margin: 0;
//}
//
//.dropdown-menu .dropdown-item {
//  font-size: 14px;
//  padding: 10px 15px;
//  transition: 0.3s;
//}
//
//.dropdown-menu .dropdown-item i {
//  margin-right: 10px;
//  font-size: 18px;
//  line-height: 0;
//}
//
//@media (min-width: 768px) {
//  .dropdown-menu-arrow::before {
//    content: "";
//    width: 13px;
//    height: 13px;
//    background: #fff;
//    position: absolute;
//    top: -7px;
//    right: 20px;
//    transform: rotate(45deg);
//    border-top: 1px solid #eaedf1;
//    border-left: 1px solid #eaedf1;
//  }
//}
//
//@-webkit-keyframes dropdown-animate {
//  0% {
//    opacity: 0;
//  }
//  100% {
//    opacity: 1;
//  }
//  0% {
//    opacity: 0;
//  }
//}
//
//@keyframes dropdown-animate {
//  0% {
//    opacity: 0;
//  }
//  100% {
//    opacity: 1;
//  }
//  0% {
//    opacity: 0;
//  }
//}
//
///* Light Backgrounds */
//.bg-primary-light {
//  background-color: #cfe2ff;
//  border-color: #cfe2ff;
//}
//
//.bg-secondary-light {
//  background-color: #e2e3e5;
//  border-color: #e2e3e5;
//}
//
//.bg-success-light {
//  background-color: #d1e7dd;
//  border-color: #d1e7dd;
//}
//
//.bg-danger-light {
//  background-color: #f8d7da;
//  border-color: #f8d7da;
//}
//
//.bg-warning-light {
//  background-color: #fff3cd;
//  border-color: #fff3cd;
//}
//
//.bg-info-light {
//  background-color: #cff4fc;
//  border-color: #cff4fc;
//}
//
//.bg-dark-light {
//  background-color: #d3d3d4;
//  border-color: #d3d3d4;
//}
//
///* Card */
//.card {
//  //margin-bottom: 30px;
//  box-shadow: 0 0 30px rgba(1, 41, 112, 0.1);
//}
//
//.card-header, .card-footer {
//  border-color: #ebeef4;
//  background-color: transparent;
//  color: #798eb3;
//  padding: 16px;
//}
//
//.card-header, .p-card-header {
//  //font-size: 18px;
//  font-weight: 700;
//  color: #012970;
//  font-family: "Lato", sans-serif;
//}
//
//.card-header, .p-card-header span {
//  color: #899bbd;
//  font-size: 14px;
//  font-weight: 400;
//}
//
//.card-body {
//  padding: 16px;
//}
//
//.card-img-overlay {
//  background-color: rgba(255, 255, 255, 0.6);
//}
//
//.list-group-item {
//  padding: 0.5rem 1rem;
//}
//
///* Alerts */
//.alert-heading {
//  font-weight: 700;
//  font-family: "Lato", sans-serif;
//  font-size: 20px;
//}
//
///* Button */
//.btn.btn-action {
//  font-size: 0.8rem;
//  font-weight: 700;
//  text-transform: uppercase;
//}
//
//.btn-group-xs > .btn, .btn-xs {
//  padding: .25rem .4rem;
//  font-size: .875rem;
//  line-height: .5;
//  border-radius: .2rem;
//  min-width: 30px;
//}
//
///* Close Button */
//.btn-close {
//  background-size: 25%;
//}
//
//.btn-close:focus {
//  outline: 0;
//  box-shadow: none;
//}
//
///* Accordion */
//.accordion-item {
//  border: 1px solid #ebeef4;
//}
//
//.accordion-button:focus {
//  outline: 0;
//  box-shadow: none;
//}
//
//.accordion-button:not(.collapsed) {
//  color: #012970;
//  background-color: #f6f9ff;
//}
//
//.accordion-flush .accordion-button {
//  padding: 15px 0;
//  background: none;
//  border: 0;
//}
//
//.accordion-flush .accordion-button:not(.collapsed) {
//  box-shadow: none;
//  color: #4154f1;
//}
//
//.accordion-flush .accordion-body {
//  padding: 0 0 15px 0;
//  color: #3e4f6f;
//  font-size: 15px;
//}
//
///* Breadcrumbs */
//.breadcrumb {
//  font-size: 14px;
//  font-family: "Lato", sans-serif;
//  color: #899bbd;
//  font-weight: 700;
//}
//
//.breadcrumb a {
//  color: #899bbd;
//  transition: 0.3s;
//}
//
//.breadcrumb a:hover {
//  color: #51678f;
//}
//
//.breadcrumb .breadcrumb-item::before {
//  color: #899bbd;
//}
//
//.breadcrumb .active {
//  color: #51678f;
//  font-weight: 700;
//}
//
///* Bordered Tabs */
//.nav-tabs-bordered {
//  border-bottom: 2px solid #ebeef4;
//}
//
//.nav-tabs-bordered .nav-link {
//  margin-bottom: -2px;
//  border: none;
//  color: #2c384e;
//}
//
//.nav-tabs-bordered .nav-link:hover, .nav-tabs-bordered .nav-link:focus {
//  color: #4154f1;
//}
//
//.nav-tabs-bordered .nav-link.active {
//  background-color: #fff;
//  color: #4154f1;
//  border-bottom: 2px solid #4154f1;
//}
//
///*--------------------------------------------------------------
//# Header
//--------------------------------------------------------------*/
//.logo {
//  line-height: 1;
//}
//
//@media (min-width: 1200px) {
//  .logo {
//    width: 280px;
//  }
//}
//
//.logo img {
//  object-fit: contain;
//  width: 32px;
//  height: 32px;
//  margin-right: 8px;
//}
//
//.logo span {
//  font-size: 26px;
//  font-weight: 900;
//  color: #012970;
//  font-family: "Lato", sans-serif;
//}
//
//.header {
//  transition: all 0.5s;
//  z-index: 997;
//  height: 54px;
//  box-shadow: 0 2px 20px rgba(1, 41, 112, 0.1);
//  background-color: #fff;
//  padding-left: 20px;
//  /* Toggle Sidebar Button */
//  /* Search Bar */
//}
//
//.header .toggle-sidebar-btn {
//  font-size: 32px;
//  padding-left: 10px;
//  cursor: pointer;
//  color: #012970;
//}
//
//.header .search-bar {
//  min-width: 360px;
//  padding: 0 20px;
//}
//
//@media (max-width: 1199px) {
//  .header .search-bar {
//    position: fixed;
//    top: 50px;
//    left: 0;
//    right: 0;
//    padding: 20px;
//    box-shadow: 0 0 15px 0 rgba(1, 41, 112, 0.1);
//    background: white;
//    z-index: 9999;
//    transition: 0.3s;
//    visibility: hidden;
//    opacity: 0;
//  }
//  .header .search-bar-show {
//    top: 60px;
//    visibility: visible;
//    opacity: 1;
//  }
//}
//
//.header .search-form {
//  width: 100%;
//}
//
//.header .search-form input {
//  font-size: 14px;
//  color: #012970;
//  border: 1px solid rgba(1, 41, 112, 0.2);
//  padding: 7px 38px 7px 8px;
//  border-radius: 3px;
//  transition: 0.3s;
//  width: 100%;
//}
//
//.header .search-form input:focus, .header .search-form input:hover {
//  outline: none;
//  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
//  border: 1px solid rgba(1, 41, 112, 0.3);
//}
//
//.header .search-form button {
//  border: 0;
//  padding: 0;
//  margin-left: -30px;
//  background: none;
//}
//
//.header .search-form button i {
//  color: #012970;
//}
//
///*--------------------------------------------------------------
//# Header Nav
//--------------------------------------------------------------*/
//.header-nav ul {
//  list-style: none;
//}
//
//.header-nav > ul {
//  margin: 0;
//  padding: 0;
//}
//
//.header-nav .nav-icon {
//  font-size: 20px;
//  color: #012970;
//}
//
//.header-nav .nav-profile {
//  color: #012970;
//}
//
//.header-nav .nav-profile img {
//  max-height: 36px;
//}
//
//.header-nav .nav-profile span {
//  font-size: 14px;
//  font-weight: 700;
//}
//
//.header-nav .badge-number {
//  position: absolute;
//  inset: 4px 6px auto auto;
//  font-weight: normal;
//  font-size: 11px;
//  padding: 3px 6px;
//}
//
//.header-nav .notifications .notification-item {
//  display: flex;
//  align-items: center;
//  padding: 15px 10px;
//  transition: 0.3s;
//}
//
//.header-nav .notifications .notification-item i {
//  margin: 0 20px 0 10px;
//  font-size: 24px;
//}
//
//.header-nav .notifications .notification-item h4 {
//  font-size: 16px;
//  font-weight: 700;
//  margin-bottom: 5px;
//}
//
//.header-nav .notifications .notification-item p {
//  font-size: 13px;
//  margin-bottom: 3px;
//  color: #919191;
//}
//
//.header-nav .notifications .notification-item:hover {
//  background-color: #f6f9ff;
//}
//
//.header-nav .messages .message-item {
//  padding: 15px 10px;
//  transition: 0.3s;
//}
//
//.header-nav .messages .message-item a {
//  display: flex;
//}
//
//.header-nav .messages .message-item img {
//  margin: 0 20px 0 10px;
//  max-height: 40px;
//}
//
//.header-nav .messages .message-item h4 {
//  font-size: 16px;
//  font-weight: 700;
//  margin-bottom: 5px;
//  color: #444444;
//}
//
//.header-nav .messages .message-item p {
//  font-size: 13px;
//  margin-bottom: 3px;
//  color: #919191;
//}
//
//.header-nav .messages .message-item:hover {
//  background-color: #f6f9ff;
//}
//
//.header-nav .profile {
//  min-width: 240px;
//  padding-bottom: 0;
//}
//
//.header-nav .profile .dropdown-header h6 {
//  font-size: 18px;
//  margin-bottom: 0;
//  font-weight: 700;
//  color: #444444;
//}
//
//.header-nav .profile .dropdown-header span {
//  font-size: 14px;
//}
//
//.header-nav .profile .dropdown-item {
//  font-size: 14px;
//  padding: 10px 15px;
//  transition: 0.3s;
//}
//
//.header-nav .profile .dropdown-item i {
//  margin-right: 10px;
//  font-size: 18px;
//  line-height: 0;
//}
//
//.header-nav .profile .dropdown-item:hover {
//  background-color: #f6f9ff;
//}
//
///*--------------------------------------------------------------
//# Sidebar
//--------------------------------------------------------------*/
//.sidebar {
//  position: fixed;
//  top: 54px;
//  left: 0;
//  bottom: 0;
//  width: 300px;
//  z-index: 996;
//  transition: all 0.3s;
//  padding: 16px;
//  overflow-y: auto;
//  box-shadow: 0 0 20px rgba(1, 41, 112, 0.1);
//  background-color: #fff;
//}
//
//@media (max-width: 1199px) {
//  .sidebar {
//    left: -300px;
//  }
//}
//
//.sidebar::-webkit-scrollbar {
//  width: 5px;
//  height: 8px;
//  background-color: #fff;
//}
//
//.sidebar::-webkit-scrollbar-thumb {
//  background-color: #aab7cf;
//}
//
//@media (min-width: 1200px) {
//  #main, #footer {
//    margin-left: 300px;
//  }
//}
//
//@media (max-width: 1199px) {
//  .toggle-sidebar .sidebar {
//    left: 0;
//  }
//}
//
//@media (min-width: 1200px) {
//  .toggle-sidebar #main, .toggle-sidebar #footer {
//    margin-left: 0;
//  }
//  .toggle-sidebar .sidebar {
//    left: -300px;
//  }
//}
//
//.sidebar-nav {
//  padding: 0;
//  margin: 0;
//  list-style: none;
//}
//
//.sidebar-nav li {
//  padding: 0;
//  margin: 0;
//  list-style: none;
//}
//
//.sidebar-nav li.submenu {
//  padding: 0 0 0 16px;
//
//  &.mm-active {
//    background-color: #00000010;
//    border-radius: 8px;
//  }
//}
//
//.sidebar-nav .nav-item {
//  margin-bottom: 5px;
//}
//
//.sidebar-nav .nav-item.mm-active .nav-link {
//  background: #f6f9ff;
//}
//
//.sidebar-nav .nav-item .nav-link fa-icon {
//  //transform-origin: top;
//  transform: rotate(90deg);
//  transition: all 0.3s ease-out;
//}
//
//.sidebar-nav .nav-item.mm-active .nav-link fa-icon {
//  transform: rotate(-0deg);
//}
//
//.sidebar-nav .nav-heading {
//  font-size: 11px;
//  text-transform: uppercase;
//  color: #899bbd;
//  font-weight: 700;
//  margin: 10px 0 5px 15px;
//}
//
//.sidebar-nav .nav-link {
//  display: flex;
//  align-items: center;
//  font-size: 14px;
//  font-weight: 700;
//  color: #4154f1;
//  transition: 0.3s;
//  //background: #f6f9ff;
//  padding: 10px 15px;
//  border-radius: 4px;
//}
//
//.sidebar-nav .nav-link i {
//  font-size: 16px;
//  margin-right: 10px;
//  color: #4154f1;
//}
//
//.sidebar-nav .nav-link.collapsed {
//  color: #012970;
//  background: #fff;
//}
//
//.sidebar-nav .nav-link.collapsed i {
//  color: #899bbd;
//}
//
//.sidebar-nav .nav-link:hover {
//  color: #4154f1;
//  background: #f6f9ff;
//}
//
//.sidebar-nav .nav-link:hover i {
//  color: #4154f1;
//}
//
//.sidebar-nav .nav-link .bi-chevron-down {
//  margin-right: 0;
//  transition: transform 0.2s ease-in-out;
//}
//
//.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
//  transform: rotate(180deg);
//}
//
//.sidebar-nav .nav-content {
//  padding: 5px 0 0 0;
//  margin: 0;
//  list-style: none;
//}
//
//.sidebar-nav .nav-content a {
//  display: flex;
//  align-items: center;
//  font-size: 14px;
//  font-weight: 700;
//  color: #012970;
//  transition: 0.3s;
//  padding: 10px 0 10px 16px;
//}
//
//.sidebar-nav .nav-content a fa-icon,
//.sidebar-nav .nav-content a i {
//  font-size: 6px;
//  margin-right: 8px;
//  line-height: 0;
//  border-radius: 50%;
//}
//
//.sidebar-nav .nav-content a:hover, .sidebar-nav .nav-content a.active {
//  color: #4154f1;
//}
//
//.sidebar-nav .nav-content a.active fa-icon,
//.sidebar-nav .nav-content a.active i {
//  background-color: #4154f1;
//}
//
///*--------------------------------------------------------------
//# Dashboard
//--------------------------------------------------------------*/
///* Filter dropdown */
//.dashboard .filter {
//  position: absolute;
//  right: 0;
//  top: 15px;
//}
//
//.dashboard .filter .icon {
//  color: #aab7cf;
//  padding-right: 20px;
//  padding-bottom: 5px;
//  transition: 0.3s;
//  font-size: 16px;
//}
//
//.dashboard .filter .icon:hover, .dashboard .filter .icon:focus {
//  color: #4154f1;
//}
//
//.dashboard .filter .dropdown-header {
//  padding: 8px 15px;
//}
//
//.dashboard .filter .dropdown-header h6 {
//  text-transform: uppercase;
//  font-size: 14px;
//  font-weight: 700;
//  letter-spacing: 1px;
//  color: #aab7cf;
//  margin-bottom: 0;
//  padding: 0;
//}
//
//.dashboard .filter .dropdown-item {
//  padding: 8px 15px;
//}
//
///* Info Cards */
//.dashboard .info-card {
//  padding-bottom: 10px;
//}
//
//.dashboard .info-card h6 {
//  font-size: 28px;
//  color: #012970;
//  font-weight: 900;
//  margin: 0;
//  padding: 0;
//}
//
//.dashboard .card-icon {
//  font-size: 32px;
//  line-height: 0;
//  width: 64px;
//  height: 64px;
//  flex-shrink: 0;
//  flex-grow: 0;
//}
//
//.dashboard .sales-card .card-icon {
//  color: #4154f1;
//  background: #f6f6fe;
//}
//
//.dashboard .revenue-card .card-icon {
//  color: #2eca6a;
//  background: #e0f8e9;
//}
//
//.dashboard .customers-card .card-icon {
//  color: #ff771d;
//  background: #ffecdf;
//}
//
///* Activity */
//.dashboard .activity {
//  font-size: 14px;
//}
//
//.dashboard .activity .activity-item .activite-label {
//  color: #888;
//  position: relative;
//  flex-shrink: 0;
//  flex-grow: 0;
//  min-width: 64px;
//}
//
//.dashboard .activity .activity-item .activite-label::before {
//  content: "";
//  position: absolute;
//  right: -11px;
//  width: 4px;
//  top: 0;
//  bottom: 0;
//  background-color: #eceefe;
//}
//
//.dashboard .activity .activity-item .activity-badge {
//  margin-top: 3px;
//  z-index: 1;
//  font-size: 11px;
//  line-height: 0;
//  border-radius: 50%;
//  flex-shrink: 0;
//  border: 3px solid #fff;
//  flex-grow: 0;
//}
//
//.dashboard .activity .activity-item .activity-content {
//  padding-left: 10px;
//  padding-bottom: 20px;
//}
//
//.dashboard .activity .activity-item:first-child .activite-label::before {
//  top: 5px;
//}
//
//.dashboard .activity .activity-item:last-child .activity-content {
//  padding-bottom: 0;
//}
//
///* News & Updates */
//.dashboard .news .post-item + .post-item {
//  margin-top: 15px;
//}
//
//.dashboard .news img {
//  width: 80px;
//  float: left;
//  border-radius: 5px;
//}
//
//.dashboard .news h4 {
//  font-size: 15px;
//  margin-left: 95px;
//  font-weight: bold;
//  margin-bottom: 5px;
//}
//
//.dashboard .news h4 a {
//  color: #012970;
//  transition: 0.3s;
//}
//
//.dashboard .news h4 a:hover {
//  color: #4154f1;
//}
//
//.dashboard .news p {
//  font-size: 14px;
//  color: #777777;
//  margin-left: 95px;
//}
//
///* Recent Sales */
//.dashboard .recent-sales {
//  font-size: 14px;
//}
//
//.dashboard .recent-sales .table thead {
//  background: #f6f6fe;
//}
//
//.dashboard .recent-sales .table thead th {
//  border: 0;
//}
//
//.dashboard .recent-sales .dataTable-top {
//  padding: 0 0 10px 0;
//}
//
//.dashboard .recent-sales .dataTable-bottom {
//  padding: 10px 0 0 0;
//}
//
///* Top Selling */
//.dashboard .top-selling {
//  font-size: 14px;
//}
//
//.dashboard .top-selling .table thead {
//  background: #f6f6fe;
//}
//
//.dashboard .top-selling .table thead th {
//  border: 0;
//}
//
//.dashboard .top-selling .table tbody td {
//  vertical-align: middle;
//}
//
//.dashboard .top-selling img {
//  border-radius: 5px;
//  max-width: 60px;
//}
//
///*--------------------------------------------------------------
//# Icons list page
//--------------------------------------------------------------*/
//.iconslist {
//  display: grid;
//  max-width: 100%;
//  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
//  gap: 1.25rem;
//  padding-top: 15px;
//}
//
//.iconslist .icon {
//  background-color: #fff;
//  border-radius: 0.25rem;
//  text-align: center;
//  color: #012970;
//  padding: 15px 0;
//}
//
//.iconslist i {
//  margin: 0.25rem;
//  font-size: 2.5rem;
//}
//
//.iconslist .label {
//  font-family: var(--bs-font-monospace);
//  display: inline-block;
//  width: 100%;
//  overflow: hidden;
//  padding: 0.25rem;
//  font-size: 12px;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//  color: #666;
//}
//
///*--------------------------------------------------------------
//# Profie Page
//--------------------------------------------------------------*/
//.profile .profile-card img {
//  max-width: 120px;
//}
//
//.profile .profile-card h2 {
//  font-size: 24px;
//  font-weight: 900;
//  color: #2c384e;
//  margin: 10px 0 0 0;
//}
//
//.profile .profile-card h3 {
//  font-size: 18px;
//}
//
//.profile .profile-card .social-links a {
//  font-size: 20px;
//  display: inline-block;
//  color: rgba(1, 41, 112, 0.5);
//  line-height: 0;
//  margin-right: 10px;
//  transition: 0.3s;
//}
//
//.profile .profile-card .social-links a:hover {
//  color: #012970;
//}
//
//.profile .profile-overview .row {
//  margin-bottom: 20px;
//  font-size: 15px;
//}
//
//.profile .profile-overview .card-title {
//  color: #012970;
//}
//
//.profile .profile-overview .label {
//  font-weight: 700;
//  color: rgba(1, 41, 112, 0.6);
//}
//
//.profile .profile-edit label {
//  font-weight: 700;
//  color: rgba(1, 41, 112, 0.6);
//}
//
//.profile .profile-edit img {
//  max-width: 120px;
//}
//
///*--------------------------------------------------------------
//# F.A.Q Page
//--------------------------------------------------------------*/
//.faq .basic h6 {
//  font-size: 18px;
//  font-weight: 700;
//  color: #4154f1;
//}
//
//.faq .basic p {
//  color: #6980aa;
//}
//
///*--------------------------------------------------------------
//# Contact
//--------------------------------------------------------------*/
//.contact .info-box {
//  padding: 28px 30px;
//}
//
//.contact .info-box i {
//  font-size: 38px;
//  line-height: 0;
//  color: #4154f1;
//}
//
//.contact .info-box h3 {
//  font-size: 20px;
//  color: #012970;
//  font-weight: 900;
//  margin: 20px 0 10px 0;
//}
//
//.contact .info-box p {
//  padding: 0;
//  line-height: 24px;
//  font-size: 14px;
//  margin-bottom: 0;
//}
//
//.contact .php-email-form .error-message {
//  display: none;
//  color: #fff;
//  background: #ed3c0d;
//  text-align: left;
//  padding: 15px;
//  margin-bottom: 24px;
//  font-weight: 700;
//}
//
//.contact .php-email-form .sent-message {
//  display: none;
//  color: #fff;
//  background: #18d26e;
//  text-align: center;
//  padding: 15px;
//  margin-bottom: 24px;
//  font-weight: 700;
//}
//
//.contact .php-email-form .loading {
//  display: none;
//  background: #fff;
//  text-align: center;
//  padding: 15px;
//  margin-bottom: 24px;
//}
//
//.contact .php-email-form .loading:before {
//  content: "";
//  display: inline-block;
//  border-radius: 50%;
//  width: 24px;
//  height: 24px;
//  margin: 0 10px -6px 0;
//  border: 3px solid #18d26e;
//  border-top-color: #eee;
//  -webkit-animation: animate-loading 1s linear infinite;
//  animation: animate-loading 1s linear infinite;
//}
//
//.contact .php-email-form input, .contact .php-email-form textarea {
//  border-radius: 0;
//  box-shadow: none;
//  font-size: 14px;
//}
//
//.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
//  border-color: #4154f1;
//}
//
//.contact .php-email-form input {
//  padding: 10px 15px;
//}
//
//.contact .php-email-form textarea {
//  padding: 12px 15px;
//}
//
//.contact .php-email-form button[type=submit] {
//  background: #4154f1;
//  border: 0;
//  padding: 10px 30px;
//  color: #fff;
//  transition: 0.4s;
//  border-radius: 4px;
//}
//
//.contact .php-email-form button[type=submit]:hover {
//  background: #5969f3;
//}
//
//@-webkit-keyframes animate-loading {
//  0% {
//    transform: rotate(0deg);
//  }
//  100% {
//    transform: rotate(360deg);
//  }
//}
//
//@keyframes animate-loading {
//  0% {
//    transform: rotate(0deg);
//  }
//  100% {
//    transform: rotate(360deg);
//  }
//}
//
///*--------------------------------------------------------------
//# Error 404
//--------------------------------------------------------------*/
//.error-404 {
//  padding: 30px;
//}
//
//.error-404 h1 {
//  font-size: 180px;
//  font-weight: 900;
//  color: #4154f1;
//  margin-bottom: 0;
//  line-height: 150px;
//}
//
//.error-404 h2 {
//  font-size: 24px;
//  font-weight: 900;
//  color: #012970;
//  margin-bottom: 30px;
//}
//
//.error-404 .btn {
//  background: #51678f;
//  color: #fff;
//  padding: 8px 30px;
//}
//
//.error-404 .btn:hover {
//  background: #3e4f6f;
//}
//
//@media (min-width: 992px) {
//  .error-404 img {
//    max-width: 50%;
//  }
//}
//
///*--------------------------------------------------------------
//# Footer
//--------------------------------------------------------------*/
//.footer {
//  padding: 20px 0;
//  font-size: 14px;
//  transition: all 0.3s;
//  border-top: 1px solid #cddfff;
//}
//
//.footer .copyright {
//  text-align: center;
//  color: #012970;
//}
//
//.footer .credits {
//  padding-top: 5px;
//  text-align: center;
//  font-size: 13px;
//  color: #012970;
//}
//
//.font-monospace {
//  font-family: 'Roboto Mono', monospace;
//  //font-size: 16px;
//}
//
//.fs-7 {
//  font-size: $h7-font-size;
//}
//
//.fs-key {
//  font-size: 0.85rem !important;
//  line-height: 1.45rem;
//}
//
//.link {
//  cursor: pointer;
//}
//
//.input-text {
//  -moz-appearance: textfield;
//}
//
//.input-text::-webkit-outer-spin-button,
//.input-text::-webkit-inner-spin-button {
//  -webkit-appearance: none;
//  margin: 0;
//}
//
.input-counter {
  font-size: 0.75rem;
  float: right;
  margin-left: auto;
  margin-top: 0.25rem;
}

.p-inputgroup {
  > .p-inputwrapper > .p-autocomplete {
    width: 100%;

    button {
      flex: none;
    }
  }
}

.p-inputgroup {
  > .p-component,
  > .p-inputwrapper > .p-autocomplete .p-autocomplete-input,
  > .p-inputwrapper > .p-inputtext,
  > .p-inputwrapper > .p-inputnumber .p-inputtext,
  > .p-inputwrapper > .p-dropdown,
  > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }
}

//.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
//  max-width: 50%; /* Ajusta el ancho máximo según tus necesidades */
//  white-space: normal; /* Permite que el texto fluya en varias líneas */
//}
//.ui-autocomplete-panel .ui-autocomplete-list li {
//  max-width: 200px; /* Ajusta el ancho máximo según tus necesidades */
//  white-space: normal; /* Permite que el texto fluya en varias líneas */
//}
//
//.form-control {
//  padding: 0.375rem 0.5rem;
//}
//
//.form-control:focus {
//  border-color: $primary;
//  box-shadow: inset 0 0 3px 0 $primary;
//}
//
//.form-control.no-validation {
//  background-image: none !important;
//  //border: 1px solid #ced4da !important;
//  padding: 0.375rem 0.5rem !important;
//}
//
//.form-control.ng-touched.ng-valid {
//  border-color: #198754;
//  padding-right: calc(1.5em + 0.5rem);
//  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
//  background-repeat: no-repeat;
//  background-position: right calc(0.375em + 0.1875rem) center;
//  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
//}
//
//.form-control.ng-touched.ng-invalid {
//  border-color: #dc3545;
//  padding-right: calc(1.5em + 0.5rem);
//  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
//  background-repeat: no-repeat;
//  background-position: right calc(0.375em + 0.1875rem) center;
//  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
//}
//
//.form-select.ng-touched.ng-invalid:not([multiple]):not([size]), .form-select.ng-touched.ng-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
//  padding-right: 4.125rem;
//  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
//  background-position: right 0.75rem center, center right 2.25rem;
//  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
//}
//
//.form-select.ng-touched.ng-invalid {
//  border-color: #dc3545;
//}
//
//.ng-select.ng-touched.ng-invalid div {
//  border-color: #dc3545;
//}
//
//.ng-select.ng-touched.ng-valid div {
//  border-color: #198754;
//}
//
//.ng-select .ng-select-container {
//  min-height: 35px;
//}
//
//.invalid-feedback {
//  display: block;
//  font-size: 0.75rem;
//}
//
//.alert {
//  padding: 0.5rem 2rem 0.5rem 0.5rem;
//}
//
//.alert-dismissible .btn-close {
//  padding: 0.75rem 0.5rem;
//}
//
//.form-label {
//  margin-bottom: 0.25rem;
//}
//
//.form-view .overview .row {
//  margin-bottom: 0.25rem;
//}
//
//// NG-DROPDOWN
//.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//  font-size: 14px;
//  padding: 4px 8px;
//}
//
//.ng-select.ng-select-single .ng-select-container {
//  height: 35px;
//}
//
//.ng-dropdown-panel .ng-dropdown-panel-items {
//  .ng-option.ng-option-marked {
//    background-color: lighten(#0d6efd, 10%);
//    color: #FFF;
//  }
//
//  .ng-option.ng-option-selected {
//    background-color: #0d6efd;
//    color: #FFF;
//  }
//
//  .ng-option.ng-option-selected.ng-option-marked {
//
//    background-color: #0d6efd;
//    color: #FFF;
//  }
//}
//
///*--------------------------------------------------------------
//# FontAwesome
//--------------------------------------------------------------*/
//fa-icon {
//  min-width: 1rem;
//}
