$primaryColor: #1B84FF !default;
$primaryLightColor: lighten(#1B84FF, 10) !default;
$primaryDarkColor: darken(#1B84FF, 10) !default;
$primaryDarkerColor: darken(#1B84FF, 20) !default;
$primaryTextColor: #FFFFFF !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../_variables';
@import '../../../../base/_components';
@import '../../extensions/extensions';
