//td > input.form-control {
//  border-radius: 0;
//}
//
//.p-datatable-table {
//  border-spacing: 0;
//  width: 100%;
//}
//
//.p-datatable {
//  font-size: 1rem;
//
//  .p-datatable-header {
//    padding: 0.5rem 1rem;
//  }
//
//  .p-datatable-thead {
//    > tr > th {
//      padding: 0.25rem !important;
//    }
//  }
//
//  .p-datatable-tbody {
//    > tr > td {
//      padding: 0.5rem;
//    }
//  }
//}
//


.overflow-visible {
  > .p-datatable-wrapper {
    overflow: visible !important;
  }
}
