@use 'sass:math';

.p-tag {
    background: var(--primary-100);
    color: $badgeTextColor;
    font-size: $badgeFontSize;
    font-weight: $badgeFontWeight;
    padding: $tagPadding;
    border-radius: $borderRadius;

    &.p-tag-success {
        background-color: var(--green-100);
        color: $successButtonBg;
    }

    &.p-tag-info {
        background-color: var(--cyan-100);
        color: $infoButtonBg;
    }

    &.p-tag-warning {
        background-color: var(--orange-100);
        color: $warningButtonBg;
    }

    &.p-tag-danger {
        background-color: var(--red-100);
        color: $dangerButtonBg;
    }

    .p-tag-icon {
        margin-right: math.div($inlineSpacing, 2);
        font-size: $badgeFontSize;
    }

    .p-icon {
        width: $badgeFontSize;
        height: $badgeFontSize;
    }
}
