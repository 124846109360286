body {
  background-color: #EBEBEB;
}

.field > label {
  margin-bottom: 0.25rem;
}

.p-message {
  margin: 0;
}



.word-break-all {
  word-break: break-all;
}

.main-container {
  @media (min-width: 1280px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }
}
