.p-datatable {
  &.borderless {
    .p-datatable-header {
      border-width: 1px 0 0 0;
    }

    .p-datatable-thead {
      > tr {
        > th {
          border-width: 1px 0 1px 1px;

          &:first-child {
            border-width: 1px 0 1px 0;
          }

          &:last-child {
            border-width: 1px 0 1px 1px;
          }
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          border-width: 1px 0 0 1px !important;

          &:first-child {
            border-width: 1px 0 0 0 !important;
          }

          &:last-child {
            border-width: 1px 0 0 1px !important;
          }
        }

        &:last-child {
          > td {
            border-width: 1px 0 1px 1px !important;

            &:first-child {
              border-width: 1px 0 1px 0 !important;
            }

            &:last-child {
              border-width: 1px 0 1px 1px !important;
            }
          }
        }
      }
    }
  }
}
