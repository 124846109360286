//.p-paginator {
//  .p-paginator-pages {
//    .p-paginator-page {
//      //min-width: 2rem;
//      //height: 2rem;
//      //margin: 0;
//    }
//  }
//}

.p-paginator {
  .p-dropdown {
    .p-dropdown-label {
      padding-top: calc($paginatorElementHeight / 5.5);
      padding-bottom: calc($paginatorElementHeight / 5.5);
    }
  }
}
