@mixin focused-ring($ring-color) {
    box-shadow: 0 0 4px 2px rgba($ring-color, 0.5);
}

@layer primeng {
    .p-button-label {
        font-weight: 700;
    }

    .p-selectbutton > .p-button,
    .p-togglebutton.p-button {
        transition: background-color $transitionDuration,
            border-color $transitionDuration, box-shadow $transitionDuration;
    }

    .p-accordion {
        .p-accordion-header {
            .p-accordion-header-link {
                transition: background-color $transitionDuration,
                    border-color $transitionDuration,
                    box-shadow $transitionDuration;
            }
        }
    }

    .p-tabview {
        .p-tabview-nav {
            li {
                .p-tabview-nav-link {
                    transition: background-color $transitionDuration,
                        border-color $transitionDuration,
                        box-shadow $transitionDuration;
                }
            }
        }
    }

    .p-tabmenu {
        .p-tabmenu-nav {
            .p-tabmenuitem {
                .p-menuitem-link {
                    transition: background-color $transitionDuration,
                        border-color $transitionDuration,
                        box-shadow $transitionDuration;
                }
            }
        }
    }

    .p-carousel {
        .p-carousel-indicators .p-carousel-indicator.p-highlight button {
            background-color: $primaryColor;
        }
    }

    .p-galleria {
        .p-galleria-indicators .p-galleria-indicator.p-highlight button {
            background-color: $primaryColor;
        }
    }

    .p-button {
        &:focus {
            @include focused-ring(scale-color($buttonBg, $lightness: 50%));
        }

        &.p-button-secondary:enabled:focus {
            @include focused-ring(
                scale-color($secondaryButtonBg, $lightness: 50%)
            );
        }

        &.p-button-success:enabled:focus {
            @include focused-ring(
                scale-color($successButtonBg, $lightness: 50%)
            );
        }

        &.p-button-info:enabled:focus {
            @include focused-ring(scale-color($infoButtonBg, $lightness: 50%));
        }

        &.p-button-warning:enabled:focus {
            @include focused-ring(
                scale-color($warningButtonBg, $lightness: 50%)
            );
        }

        &.p-button-help:enabled:focus {
            @include focused-ring(scale-color($helpButtonBg, $lightness: 50%));
        }

        &.p-button-danger:enabled:focus {
            @include focused-ring(
                scale-color($dangerButtonBg, $lightness: 50%)
            );
        }
    }

    .p-datatable {
        .p-datatable-tbody {
            > tr {
                &.p-datatable-dragpoint-top > td {
                    box-shadow: inset 0 2px 0 0 $primaryColor;
                }

                &.p-datatable-dragpoint-bottom > td {
                    box-shadow: inset 0 -2px 0 0 $primaryColor;
                }
            }
        }
    }

    .p-speeddial-item {
        &.p-focus > .p-speeddial-action {
            @include focused-ring(scale-color($buttonBg, $lightness: 50%));
        }
    }

    .p-toast-message {
        backdrop-filter: blur(10px);
    }

    .p-inline-message-text {
        font-weight: 500;
    }

    .p-picklist-buttons .p-button,
    .p-orderlist-controls .p-button {
        transition: opacity $transitionDuration,
            background-color $transitionDuration, color $transitionDuration,
            border-color $transitionDuration, box-shadow $transitionDuration;
    }

    .p-steps {
        .p-steps-item {
            &.p-highlight {
                .p-steps-number {
                    background: $primaryColor;
                    color: $primaryTextColor;
                }
            }
        }
    }
}

@layer primeng {
  @import "./buttons";
  @import "./datatables";
  @import "./dialog";
  @import "./fonts";
  @import "./inputs";
  @import "./tables";
  @import "./custom";
  @import "./paginator";
}
